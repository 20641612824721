import { Routes, Route } from "react-router-dom";

import Orders from "./pages/Orders";
import Products from "./pages/Products";

import Home from "./pages/Home";

import Login from "./pages/Login";
import Category from "./pages/Category";
import Banners from "./pages/Banners";
import ProductList from "./pages/ProductList";
import Coupons from "./pages/Coupons";
import Customers from "./pages/Customers";
import Reviews from "./pages/Reviews";
import { AuthContextProvider } from "./context/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute";
import BannersEdit from "./pages/BannersEdit";
import CategoryEdit from "./pages/CategoryEdit";
import ProductListEdit from "./pages/ProductListEdit";
import { NavContext } from "./context/NavContext";
import { useState } from "react";
import OrderDetail from "./pages/OrderDetail";
import CouponEdit from "./pages/CouponEdit";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ContactUsPage from "./pages/ContactUsPage";

function App() {
  const [navBool, setNavBool] = useState(false);
  function changeBool() {
    setNavBool(!navBool);
  }

  return (
    <>
      <NavContext.Provider value={{ navBool }}>
        <AuthContextProvider>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route
              path="/home"
              element={
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>
              }
            />

            <Route
              path="/orders"
              element={
                <ProtectedRoute>
                  <Orders />
                </ProtectedRoute>
              }
            />
            <Route
              path="/products"
              element={
                <ProtectedRoute>
                  <Products />
                </ProtectedRoute>
              }
            />
            <Route
              path="/categories"
              element={
                <ProtectedRoute>
                  <Category />
                </ProtectedRoute>
              }
            />
            <Route
              path="/banners"
              element={
                <ProtectedRoute>
                  <Banners />
                </ProtectedRoute>
              }
            />
            <Route
              path="/productlist"
              element={
                <ProtectedRoute>
                  <ProductList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/customers"
              element={
                <ProtectedRoute>
                  <Customers />
                </ProtectedRoute>
              }
            />
            <Route
              path="/coupons"
              element={
                <ProtectedRoute>
                  <Coupons />
                </ProtectedRoute>
              }
            />
            <Route
              path="/reviews"
              element={
                <ProtectedRoute>
                  <Reviews />
                </ProtectedRoute>
              }
            />
            <Route
              path="/bannersedit/:id"
              element={
                <ProtectedRoute>
                  <BannersEdit />
                </ProtectedRoute>
              }
            >
              <Route path=":id" />
            </Route>

            <Route
              path="/categoryedit/:id"
              element={
                <ProtectedRoute>
                  <CategoryEdit />
                </ProtectedRoute>
              }
            >
              <Route path=":id" />
            </Route>

            <Route
              path="/productedit/:id"
              element={
                <ProtectedRoute>
                  <ProductListEdit />
                </ProtectedRoute>
              }
            >
              <Route path=":id" />
            </Route>

            <Route
              path="/orderdetail/:id"
              element={
                <ProtectedRoute>
                  <OrderDetail />
                </ProtectedRoute>
              }
            >
              <Route path=":id" />
            </Route>

            <Route
              path="/couponedit/:id"
              element={
                <ProtectedRoute>
                  <CouponEdit />
                </ProtectedRoute>
              }
            >
              <Route path=":id" />
            </Route>
            <Route path="/privacypolicy" element={<PrivacyPolicy />}></Route>
            <Route path="/contactus" element={<ContactUsPage />}></Route>
          </Routes>
        </AuthContextProvider>
      </NavContext.Provider>
    </>
  );
}

export default App;
