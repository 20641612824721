import { createContext, useContext, useEffect, useState } from "react";
import { auth } from "../firebase";
import { onAuthStateChanged, signInWithEmailAndPassword, signOut } from "firebase/auth";
const AuthContext = createContext();
export function AuthContextProvider({ children }) {
  const [user,setUser] = useState({})
function logIn(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }
  function logOut() {
    return signOut(auth);
  }
  useEffect(() => {
    const unsubscribed = onAuthStateChanged(auth, (currentUser) => {
        setUser(currentUser)
        
    })
    return () => {
        unsubscribed()
    }
},[])
const [navBool,setNavBool] = useState(false)
const [activeItem, setActiveItem] = useState("item1");
 
 return (
    <AuthContext.Provider value={{ logIn, logOut ,user,navBool,setNavBool,activeItem, setActiveItem}}>
      {children}
    </AuthContext.Provider>
  );
}
export function UserAuth() {
  return useContext(AuthContext);
}
