import React, { useEffect, useState } from "react";
import "../Category.css";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import defaultimage from "../assets/d.png";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Switch } from "@mui/material";
import { MdDelete } from "react-icons/md";
import { RiPencilFill } from "react-icons/ri";
import { AiOutlineDown, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import Numbers from "../components/Numbers";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  query,
  setDoc,
} from "firebase/firestore";
import { db, storage } from "../firebase";
import { v4 } from "uuid";
import { BsChevronDown } from "react-icons/bs";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Category = () => {
  const handle = (i) => {
    setCurrentPage(i);
  };
  const notify = (e) =>
    toast.info(e, {
      position: "bottom-left",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setPostPerPage] = useState(15);
  const lastIndex = currentPage * postPerPage;
  const firstIndex = lastIndex - postPerPage;

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const [row, setRow] = useState([]);

  const [filename, setFileName] = useState("Choose File");
  const [img, setImg] = useState(defaultimage);
  const handlefile = (event) => {
    const file = event.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      localStorage.setItem("image", reader.result);
      setImg(localStorage.getItem("image"));
    };

    var x = event.target.files[0].name;
    setFileName(x);
    setImg(event.target.value);
  };

  const [category, setCategory] = useState("");
  const [status, setStatus] = useState("");
  const [imgFile, setImgFile] = useState("");
  const [priority, setPriority] = useState("");
  const AddData = async (e) => {
    e.preventDefault();
    const id = v4();

    if (category === "") {
      notify("Forgot to add a category name");
      return;
    }

    if (imgFile === "") {
      notify("Forgot to add an image");
      return;
    }

    if (status === "") {
      notify("Please select a status");
    } else {
      try {
        const refs = doc(db, "category", id);
        const imgRef = ref(storage, new Date().toString());
        const uploadTask = uploadBytesResumable(imgRef, imgFile);

        uploadTask.on(
          (error) => {
            console.log(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              setDoc(refs, {
                category_id: id,
                name: category,
                status: status,
                img: downloadURL,
                priority: priority,
              }).catch((error) => {
                console.error(error);
                notify("Failed to add data");
              });
            });
          }
        );
        console.log("IMAGEEEEEEEEEEEE" + img);
        notify("Successfully added");
        setCategory("");
        setImg(defaultimage);
        setFileName("Choose File");
      } catch (error) {
        console.error(error);
        notify("Failed to add data");
      }
    }
  };

  const getdata = () => {
    const q = query(collection(db, "category"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const list = [];
      querySnapshot.forEach((doc) => {
        list.push(doc.data());
      });
      setRow(list);
    });
  };
  useEffect(() => {
    getdata();
  }, []);
  const [currentID, setCurrentID] = useState("");
  const [promt, setPromt] = useState(false);

  return (
    <>
      <Navbar title="Category" />
      <Sidebar />
      <div className="Category">
        <div className="contain">
          <ToastContainer
            position="bottom-left"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
          <div className="CategoryContain">
            <p>Category</p>
            <div className="CategoryBox">
              <p>Add New Category</p>
              <hr />
              <div className="CategoryInputs">
                <div className="CategoryInput">
                  <label>Name</label>
                  <input
                    type="text"
                    placeholder="New Category"
                    onChange={(e) => {
                      setCategory(e.target.value);
                    }}
                  />
                </div>
                <div className="CategoryInput">
                  <label>Priority</label>
                  <input
                    type="text"
                    placeholder="0"
                    onChange={(e) => {
                      setPriority(e.target.value);
                    }}
                  />
                </div>
                <div className="CategoryInput">
                  <label>Status</label>
                  <select
                    onChange={(e) => {
                      if (e.target.value === "true") {
                        setStatus(true);
                      } else {
                        setStatus(false);
                      }
                    }}
                  >
                    <option value="" disabled>
                      --Status--
                    </option>
                    <option value={""}>None</option>
                    <option value={"true"}>Available</option>
                    <option value={"false"}>Not Available</option>
                  </select>
                  <BsChevronDown className="iconDownCategory" />
                </div>

                <div className="CategoryInput">
                  <div className="ProductsInput6">
                    <p style={{ color: "gray" }}>
                      Image <span style={{ color: "red" }}> * (Ratio 1:1)</span>
                    </p>
                    <div class="custom-file-wrp2">
                      <div class="filename">{filename}</div>
                      <div className="custom-file">
                        <label htmlFor="fileupload">Browse</label>
                        <input
                          type="file"
                          id="fileupload"
                          onChange={(e) => {
                            handlefile(e);
                            setImgFile(e.target.files[0]);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <img className="CategoryImage" src={img} alt="" />

                <div>
                  <button className="CategoryButton" onClick={AddData}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
            <div className="CategoryBoxTable">
              {promt && (
                <div className="BannerPromt">
                  <p>Are you sure you want to delete?</p>
                  <div className="BannerPromtButton">
                    <button
                      onClick={async function () {
                        setPromt(false);

                        await deleteDoc(doc(db, "category", currentID));
                      }}
                    >
                      Yes
                    </button>
                    <button onClick={() => setPromt(false)}>No</button>
                  </div>
                </div>
              )}
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead className="TableTitles">
                    <TableRow>
                      <TableCell align="left">NAME</TableCell>
                      <TableCell align="left">STATUS</TableCell>
                      <TableCell align="left">PRIORITY</TableCell>
                      <TableCell align="left">ACTION</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.slice(firstIndex, lastIndex).map((row) => (
                      <TableRow
                        key={row.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="left">{row.name}</TableCell>

                        <TableCell align="left">
                          <Switch
                            {...label}
                            checked={row.status}
                            onClick={async function () {
                              await setDoc(
                                doc(db, "category", row.category_id),
                                {
                                  ...row,
                                  status: !row.status,
                                }
                              );
                            }}
                          />
                        </TableCell>
                        <TableCell align="left">
                          <p>{row.priority}</p>
                        </TableCell>
                        <TableCell align="left">
                          <Link to={`/categoryedit/${row.category_id}`}>
                            <RiPencilFill
                              className="iconAction"
                              color="gray"
                              size={20}
                            />
                          </Link>

                          <MdDelete
                            onClick={() => {
                              setCurrentID(row.category_id);
                              setPromt(true);
                            }}
                            className="iconAction"
                            color="gray"
                            size={20}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>

          <Numbers
            rowLength={row.length}
            postPerPage={postPerPage}
            handle={handle}
            currentPage={currentPage}
          />
        </div>
      </div>
    </>
  );
};

export default Category;
