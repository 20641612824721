import { async } from "@firebase/util";
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  setDoc,
} from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import React, { useEffect, useState } from "react";
import { BiPlusCircle } from "react-icons/bi";
import { BsChevronDown } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import { v4 } from "uuid";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import { db, storage } from "../firebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BannersEdit = () => {
  const [data, setData] = useState({
    title: "",
    image: "",
    prioritary: "",
    createdAt: "",
    updatedAt: "",
    isActive: false,
    category_id: "",
    product_id: "",
    banner_type: "",
  });
  const item_id = useParams();
  const [filename, setFileName] = useState("Choose File");
  const [imgFile, setImgFile] = useState("");
  const [img, setImg] = useState();
  const notify = (e) =>
    toast.info(e, {
      position: "bottom-left",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const GetData = async () => {
    const docRef = doc(db, "banners", item_id.id);
    const docSnap = await getDoc(docRef);
    setData(docSnap.data());
  };
  useEffect(() => {
    GetData();
  }, []);

  const handlefile = (event) => {
    const file = event.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      localStorage.setItem("image", reader.result);
      setImg(localStorage.getItem("image"));
    };

    var x = event.target.files[0].name;
    setFileName(x);
    setImg(event.target.value);
  };
  const [dataList, setDataList] = useState([]);

  const [type, setType] = useState("");
  const handleData = (e) => {
    if (e.target.value === "promotional") {
      setData({
        ...data,
        banner_type: "promotional",
        product_id: "",
        category_id: "",
      });
    }
    if (e.target.value === "none") {
      setData({
        ...data,
        banner_type: "none",
        product_id: "",
        category_id: "",
      });
    }
    setType(e.target.value);
    const q = query(collection(db, e.target.value));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const list = [];
      querySnapshot.forEach((doc) => {
        list.push(doc.data());
      });
      setDataList(list);
    });
  };

  const AddData = (e) => {
    e.preventDefault();

    const refs = doc(db, "banners", item_id.id);
    const imgRef = ref(storage, new Date().toString());
    if (imgFile === "") {
      notify("Please add an image");
    } else if (data.title === "") {
      notify("Please add a title");
    } else if (data.banner_type === "none" || data.banner_type === "") {
      notify("Please select a banner type");
    } else {
      if (imgFile) {
        const uploadTask = uploadBytesResumable(imgRef, imgFile);
        uploadTask.on(
          (error) => {
            console.log(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              setDoc(refs, {
                id: item_id.id,
                title: data.title,
                image: downloadURL,
                prioritary: data.prioritary,
                createdAt: new Date().toString(),
                updatedAt: data.updatedAt,
                isActive: data.isActive,
                category_id: data.category_id,
                product_id: data.product_id,
                banner_type: data.banner_type,
              });
            });
          }
        );
      }

      setDoc(refs, {
        id: item_id.id,
        title: data.title,
        image: data.image,
        prioritary: data.prioritary,
        createdAt: new Date().toString(),
        updatedAt: data.updatedAt,
        isActive: data.isActive,
        category_id: data.category_id,
        product_id: data.product_id,
        banner_type: data.banner_type,
      });
      navigate("/banners");
    }
  };

  const navigate = useNavigate();
  return (
    <>
      <Navbar title="Banner" />
      <Sidebar />
      <div className="Banner">
        <div className="contain">
          <div className="BannerContain">
            <div className="ProductsTitle">
              <BiPlusCircle size={25} />
              <p>Add New Banner</p>
            </div>
            <div className="BannerBox">
              <div className="BannerBoxInputs">
                <div className="BannerBoxInput">
                  <p>Title</p>
                  <input
                    type="text"
                    placeholder="New banner"
                    value={data.title}
                    onChange={(e) =>
                      setData({ ...data, title: e.target.value })
                    }
                  />
                </div>

                <div>
                  <div className="BannerBoxInput">
                    <p>Banner Type</p>
                    <select
                      name=""
                      id=""
                      onChange={handleData}
                      defaultValue={data.banner_type}
                    >
                      <option value="none">--Select--</option>
                      <option value="category">Category Wise</option>
                      <option value="products">Product Wise</option>
                      <option value="promotional">Promotional</option>
                    </select>
                    <BsChevronDown className="BannericonDown" />
                  </div>
                  <div className="BannerTypeContain">
                    {dataList.map((i) => {
                      return (
                        <div className="bannerTypeList">
                          <p>{i.name}</p>
                          <button
                            onClick={(e) => {
                              if (type == "category") {
                                setData({
                                  ...data,
                                  category_id: i.category_id,
                                });
                                setData({
                                  ...data,
                                  banner_type: type,
                                });
                              } else if (type == "products") {
                                setData({ ...data, product_id: i.id });
                                setData({
                                  ...data,
                                  banner_type: type,
                                });
                              } else {
                                // setData({
                                //   ...data,
                                //   banner_type: "Promotional",
                                // });
                              }
                            }}
                          >
                            select
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="BannerBoxInput">
                  <p>Availability</p>
                  <select
                    name=""
                    id=""
                    onChange={(e) => {
                      if (e.target.value === "true") {
                        setData({ ...data, isActive: true });
                      } else {
                        setData({ ...data, isActive: false });
                      }
                    }}
                  >
                    <option value="" disabled>
                      --Select--
                    </option>
                    <option value={"true"}>Active</option>
                    <option value={"false"}>Not Active</option>
                  </select>
                  <BsChevronDown className="BannericonDown" />
                </div>
                <div className="BannerBoxInput">
                  <p>Prioritary</p>
                  <input
                    value={data.prioritary}
                    type="text"
                    placeholder="Prioritary"
                    onChange={(e) =>
                      setData({ ...data, prioritary: e.target.value })
                    }
                  />
                </div>

                <button className="Bannerbutton" onClick={AddData}>
                  Save
                </button>
              </div>

              <div className="BannerBoxInputs">
                <div className="BannerBoxInput">
                  <p style={{ color: "gray" }}>
                    Image <span style={{ color: "red" }}> * (Ratio 1:1)</span>
                  </p>
                  <div class="custom-file-wrp2">
                    <div class="filename1">{filename}</div>
                    <div className="custom-file">
                      <label htmlFor="fileupload">Browse</label>
                      <input
                        type="file"
                        id="fileupload"
                        onChange={(e) => {
                          handlefile(e);

                          setImgFile(e.target.files[0]);
                        }}
                      />
                    </div>
                  </div>
                  <img
                    className="BannerinputImage"
                    src={img ? img : data.image}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BannersEdit;
