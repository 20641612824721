// PrivacyPolicy.jsx
import React from "react";
import "../PrivacyPolicy.css"; // Import external CSS file

const PrivacyPolicy = () => {
  return (
    <div className="privacy-container">
      <h2 className="privacy-heading">Privacy Policy for Kiosk App</h2>
      <br></br>
      <br></br>
      <p>
        This Privacy Policy describes how Kiosk ("we," "us," or "our") collects,
        uses, and shares personal information when you use our mobile
        application (the "App"). By using the App, you agree to the practices
        described in this policy.
      </p>

      <h3>Information We Collect</h3>

      <p>
        <strong>Information You Provide:</strong>
        <br />
        <strong>Account Information:</strong> When you create an account, we
        collect your name, email address, and other optional information.
        <br />
        <strong>Order Information:</strong> When you make a purchase, we collect
        information necessary to process your order, such as your shipping
        address and payment details.
        <br />
        <strong>Communications:</strong> If you contact us, we may collect the
        content of your messages and any attachments.
      </p>

      <p>
        <strong>Automatically Collected Information:</strong>
        <br />
        <strong>Usage Information:</strong> We collect information about your
        interactions with the App, such as the pages you view and the actions
        you take.
        <br />
        <strong>Device Information:</strong> We automatically collect
        information about the device you use to access the App, including device
        model, operating system, and unique device identifiers.
      </p>

      <h3>How We Use Your Information</h3>

      <p>We use the information we collect for various purposes, including:</p>

      <ul>
        <li>
          <strong>Providing Services:</strong> To provide, personalize, and
          improve our services, process transactions, and fulfill your orders.
        </li>
        <li>
          <strong>Communications:</strong> To communicate with you, respond to
          your inquiries, and send important notices.
        </li>
      </ul>

      <h3>Information Sharing</h3>

      <p>
        We do not sell, trade, or otherwise transfer your personal information
        to third parties without your consent, except as described below:
      </p>

      <ul>
        <li>
          <strong>Service Providers:</strong> We may share your information with
          third-party service providers who help us operate the App and provide
          services.
        </li>
        <li>
          <strong>Legal Compliance:</strong> We may disclose your information to
          comply with applicable laws, regulations, or legal processes.
        </li>
      </ul>

      <h3>Your Choices</h3>

      <p>
        You can control the information we collect through the App by adjusting
        your device settings or contacting us. However, certain features of the
        App may not be available if you choose not to provide certain
        information.
      </p>

      <h3>Security</h3>

      <p>
        We take reasonable measures to protect the security of your personal
        information. However, no method of transmission over the internet or
        electronic storage is entirely secure.
      </p>

      <h3>Changes to This Policy</h3>

      <p>
        We may update this Privacy Policy to reflect changes in our practices or
        for other operational, legal, or regulatory reasons. We will notify you
        of any material changes by posting the updated policy on our website or
        through other communication methods.
      </p>

      <h3>Contact Us</h3>

      <p>
        If you have any questions or concerns about this Privacy Policy, please
        contact us at{" "}
        <a href="mailto:support@kiosk.et" className="contact-link">
          support@kiosk.et
        </a>
        .
      </p>
    </div>
  );
};

export default PrivacyPolicy;
