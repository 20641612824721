import React from "react";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

const Numbers = ({ rowLength, handle, currentPage, postPerPage }) => {
  const numList = [];
  for (let i = 1; i <= Math.ceil(rowLength / postPerPage); i++) {
    numList.push(i);
  }
  return (
    <div  className="CustomerNumbers">
      <AiOutlineLeft size={13} />
      {numList.map((i) => (
        <p
          onClick={() => handle(i)}
          className={i == currentPage ? "dynamic" : ""}
        >
          {i}
        </p>
      ))}

      <AiOutlineRight size={13} />
    </div>
  );
};

export default Numbers;
