import React, { useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import "../Customers.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Switch } from "@mui/material";
import { TiArrowUnsorted } from "react-icons/ti";
import { SlEye } from "react-icons/sl";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import Numbers from "../components/Numbers";
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase";
const Customers = () => {
  const handle = (i) => {
    setCurrentPage(i);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setPostPerPage] = useState(15);
  const lastIndex = currentPage * postPerPage;
  const firstIndex = lastIndex - postPerPage;
  const [search, setSearch] = useState("");

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const [load, setLoad] = useState(true);

  const [data, setData] = useState([]);

  const getdata = () => {
    const q = query(collection(db, "orders"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const list = [];
      querySnapshot.forEach((doc) => {
        list.push(doc.data());
      });
      setData(list);

      setLoad(false);
    });
  };
  useEffect(() => {
    getdata();
  });
  const [currentID, setCurrentID] = useState("");
  const [promt, setPromt] = useState(false);

  const [Statuspromt, setStatusPromt] = useState(false);

  const getDelivered = (id) => {
    const delivered = data.filter((i) => i.status === "Delivered");
    const newDelivered = delivered.filter((i) => i.user.id === id);
    return newDelivered.length;
  };

  const getCancelled = (id) => {
    const cancelled = data.filter((i) => i.status === "Cancelled");
    const newCancelled = cancelled.filter((i) => i.user.id === id);
    return newCancelled.length;
  };

  const [vals, setVals] = useState([]);

  const [userData, setUserData] = useState({ is_active: false });

  const customers = data.filter(
    (product, index, self) =>
      index === self.findIndex((p) => p.user?.id === product.user?.id)
  );
  useEffect(() => {
    const fetchData = async () => {
      const newData = {};
      const promises = customers.map((user) => {
        const docRef = doc(db, "users", user.user?.id);
        return getDoc(docRef).then((docSnap) => {
          newData[user.user?.id] = docSnap.data();
        });
      });
      await Promise.all(promises);
      setUserData(newData);
    };
    fetchData();
  }, [data]);

  return (
    <>
      <Navbar title="Customers" />
      <Sidebar />
      <div className="Customers">
        <div className="contain">
          <div className="CustomersContain">
            <div className="CustomerTitle">
              <h2>Customer</h2>
              <p>{data.length}</p>
            </div>

            <div className="CustomersPageBox">
              <div className="CategoryPageSearch">
                <BiSearch size={18} className="bannerIcon" />
                <input
                  type="text"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search here"
                />
                <button>Search</button>
              </div>

              <div className="CustomerPageTable">
                {Statuspromt && (
                  <div className="CustomerPromt">
                    <p>Are you sure you want to change status?</p>
                    <div className="CustomerPromtButton">
                      <button
                        onClick={async function () {
                          setStatusPromt(false);

                          await updateDoc(doc(db, "users", currentID), {
                            is_active: !userData[currentID].is_active,
                          });
                        }}
                      >
                        Yes
                      </button>
                      <button onClick={() => setStatusPromt(false)}>No</button>
                    </div>
                  </div>
                )}
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead className="TableTitles">
                      <TableRow>
                        <TableCell align="left">
                          <div className="TableProduct">
                            <p> Name</p>
                            <TiArrowUnsorted color="gray" />
                          </div>
                        </TableCell>

                        <TableCell align="left">
                          <div className="TableProduct">
                            {" "}
                            <p>PHONE</p>
                            <TiArrowUnsorted color="gray" />
                          </div>
                        </TableCell>

                        <TableCell align="left">
                          <div className="TableProduct">
                            <p> DELIVERED ORDER</p>
                            <TiArrowUnsorted color="gray" />
                          </div>
                        </TableCell>
                        <TableCell align="left">
                          <div className="TableProduct">
                            <p>CANCELED ORDER</p>
                            <TiArrowUnsorted color="gray" />
                          </div>
                        </TableCell>
                        <TableCell align="left">
                          <div className="TableProduct">
                            <p>ACTIVE/INACTIVE</p>
                            <TiArrowUnsorted color="gray" />
                          </div>
                        </TableCell>
                        {/* <TableCell align="left">
                          <div className="TableProduct">
                            <p>ACTION</p>
                            <TiArrowUnsorted color="gray" />
                          </div>
                        </TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {load ? (
                        <div class="lds-spinner">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      ) : (
                        customers
                          .filter((val) => {
                            if (search === "") {
                              return val;
                            } else if (
                              val.user?.name
                                .toLowerCase()
                                .includes(search.toLowerCase())
                            ) {
                              return val;
                            }
                          })
                          .slice(firstIndex, lastIndex)
                          .map((row) => (
                            <TableRow
                              key={row.user?.id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell align="left">
                                {row.user?.name}
                              </TableCell>
                              <TableCell align="left">
                                {row.user?.phone}
                              </TableCell>
                              <TableCell align="left">
                                <p className="Rowcolor">
                                  {getDelivered(row.user?.id)}
                                </p>
                              </TableCell>
                              <TableCell align="left">
                                <p className="Rowcolor2">
                                  {getCancelled(row.user?.id)}
                                </p>
                              </TableCell>

                              <TableCell align="left">
                                <Switch
                                  checked={userData[row.user?.id]?.is_active}
                                  onClick={() => {
                                    setStatusPromt(true);
                                    setCurrentID(row.user?.id);
                                  }}
                                  {...label}
                                />
                              </TableCell>

                              {/* <TableCell align="left">
                                <SlEye className="Eye" />
                              </TableCell> */}
                            </TableRow>
                          ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <Numbers
                rowLength={data.length}
                postPerPage={postPerPage}
                handle={handle}
                currentPage={currentPage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Customers;
