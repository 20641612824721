import { createContext, useContext, useState } from "react";

// const NavContext = createContext();
// export function NavContextProvider({ children }) {
//   const [navBool,setNavBool] = useState(false)
//   function changeBool (){
//     setNavBool(!navBool)
//   }
//  return (
//     <NavContext.Provider value={{navBool,changeBool}}>
//       {children}
//     </NavContext.Provider>
//   );
// }
// export function Navcon() {
//     return useContext(NavContext);
//   }
export const NavContext = createContext();

