import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import { BsEye, BsEyeSlash, BsShieldFillExclamation } from "react-icons/bs";
import { Link } from "react-router-dom";
import "../login.css";
import logo from "../assets/kiosk.svg";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const Login = () => {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const { logIn } = UserAuth();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handle = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);
    try {
      await logIn(email, password);
      navigate("/home");
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);

      setError(true);
    }
  };
  return (
    <div className="loginContainer">
      <img className="image" src={logo} alt="" />
      <div>
        {error ? (
          <div className="LoginError">
            <BsShieldFillExclamation size={25} />
            <div>
              <p style={{ marginBottom: "6px" }}>Error: 0</p>
              <p>Credentials does not match</p>
            </div>
          </div>
        ) : null}
      </div>
      <div className="loginForm">
        <p className="loginSubTitle">Sign In</p>
        <p className="loginDetails">(Admin or Employee Sign In)</p>

        <form onSubmit={handle}>
          <div className="formContain">
            <label htmlFor="">Your Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              placeholder="email@address.com"
              required
            />
            <div>
              <label htmlFor="">Your Password</label>
            </div>
            <input
              required
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              type={passwordVisible ? "text" : "password"}
              placeholder="+6 characters required"
            />
            {passwordVisible ? (
              <BsEye
                className="eye"
                size={23}
                onClick={togglePasswordVisibility}
              />
            ) : (
              <BsEyeSlash
                className="eye"
                size={23}
                onClick={togglePasswordVisibility}
              />
            )}

            <button className="SignInButton">
              {loading ? (
                <AiOutlineLoading3Quarters className="LoginLoading" size={25} />
              ) : (
                <p>Sign in</p>
              )}
            </button>
          </div>
        </form>
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <Link to="/privacypolicy">Privacy Policy</Link>
      <Link to="/contactus">Contact Us</Link>
    </div>
  );
};

export default Login;
