// ContactUsPage.jsx
import React, { useState, useRef } from "react";
import styled from "styled-components";
import emailjs from "@emailjs/browser";

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const ContactUsPageWrapper = styled.div`
  max-width: 500px;
  margin: 0 auto;
  padding: 100px;
  background-color: #f4f4f4;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  h2 {
    color: #333;
  }

  p {
    margin-bottom: 20px;
    color: #555;
  }

  form {
    display: flex;
    flex-direction: column;

    label {
      text-align: left;
      margin-bottom: 8px;
      color: #555;
    }

    input,
    textarea {
      width: 100%;
      padding: 20px;
      margin-bottom: 15px;
      border: 1px solid #ddd;
      border-radius: 4px;
      font-size: 16px;
      box-sizing: border-box; // Ensure the padding is included in the width
    }

    button {
      background-color: #007bff;
      color: #fff;
      padding: 12px;
      border: none;
      border-radius: 4px;
      font-size: 16px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #0056b3;
      }
    }
  }
`;

const ContactUsPage = () => {
  const [from_name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const form = useRef();
  const [isSend, setIsSend] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    setIsLoading(true);
    e.preventDefault();
    // Add your logic here to handle the form submission
    console.log("Name:", from_name);
    console.log("Email:", email);
    console.log("Message:", message);
    emailjs
      .sendForm(
        "service_5cr36pi",
        "template_qvxybdp",
        form.current,
        "_9zd5aZiJDyfF0_eQ"
      )
      .then((result) => {
        setIsSend(true);
        setIsLoading(false);
        setTimeout(() => {
          setIsSend(false);
        }, 2500);
        console.log(result.text);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error.text);
      });
    // You can send this data to a server or perform any other necessary action
  };

  return (
    <CenteredContainer>
      <ContactUsPageWrapper>
        <h2>Contact Us</h2>
        <p>Let's get in touch with Kiosk App support</p>

        <form ref={form} onSubmit={handleSubmit}>
          <label htmlFor="from_name">Name</label>
          <input
            type="text"
            id="from_name"
            value={from_name}
            onChange={handleNameChange}
            required
          />

          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={handleEmailChange}
            required
          />

          <label htmlFor="message">Your message here</label>
          <textarea
            id="message"
            value={message}
            onChange={handleMessageChange}
            required
          />

          <button type="submit"> {isLoading ? "Sending..." : "Send"}</button>
          {isSend && <div>Email has been sent successfully</div>}
        </form>
      </ContactUsPageWrapper>
    </CenteredContainer>
  );
};

export default ContactUsPage;
