import React, { useEffect, useState } from "react";
import "../ProductList.css";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import { TiArrowUnsorted } from "react-icons/ti";
import { BiSearch } from "react-icons/bi";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Switch } from "@mui/material";
import { MdDelete } from "react-icons/md";
import { RiPencilFill } from "react-icons/ri";
import { db } from "../firebase";
import {
  collection,
  getDocs,
  doc,
  setDoc,
  deleteDoc,
  onSnapshot,
  query,
  updateDoc,
} from "firebase/firestore";
import { ref } from "firebase/storage";
import { FaSleigh } from "react-icons/fa";
import { Link } from "react-router-dom";
import Numbers from "../components/Numbers";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ProductList = () => {
  const notify = () =>
    toast.info(`Status Updated`, {
      position: "bottom-left",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  const [search, setSearch] = useState("");
  const [load, setLoad] = useState(true);
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setPostPerPage] = useState(15);
  const lastIndex = currentPage * postPerPage;
  const firstIndex = lastIndex - postPerPage;

  const handle = (i) => {
    setCurrentPage(i);
  };
  const [data, setData] = useState([]);
  const getdata = () => {
    const q = query(collection(db, "products"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const list = [];
      querySnapshot.forEach((doc) => {
        list.push(doc.data());
      });
      setData(list);
      setLoad(false);
    });
  };
  useEffect(() => {
    getdata();
  }, []);
  const [currentID, setCurrentID] = useState("");
  const [promt, setPromt] = useState(false);
  const [clicked, setclicked] = useState(false);
  const [Statuspromt, setStatusPromt] = useState(false);

  const active = data.filter((i) => i.isActive === true);
  const notActive = data.filter((i) => i.isActive === false);
  return (
    <>
      <Navbar title="Product List" />
      <Sidebar />
      <div className="ProductList">
        <div className="contain">
          <ToastContainer
            position="bottom-left"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
          <div className="ProductListContain">
            <div className="ProductListTop">
              <div className="ProductListTop1">
                <p>Product List</p>

                <p>{active.length}</p>
                <p>{notActive.length}</p>
                <p>{data.length}</p>
              </div>
            </div>

            <div>
              <div>
                <div className="ProductTitleContain">
                  <div className="Bannertitle2">
                    <BiSearch size={18} className="bannerIcon" />
                    <input
                      type="text"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      placeholder="Search here"
                    />
                    <button>Search</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="ProductListTableContain">
              {promt && (
                <div className="ProductPromt">
                  <p>Are you sure you want to delete?</p>
                  <div className="ProductPromtButton">
                    <button
                      onClick={async function () {
                        setPromt(false);

                        await deleteDoc(doc(db, "products", currentID));
                      }}
                    >
                      Yes
                    </button>
                    <button onClick={() => setPromt(false)}>No</button>
                  </div>
                </div>
              )}

              {Statuspromt && (
                <div className="ProductPromt">
                  <p>Are you sure you want to change status?</p>
                  <div className="ProductPromtButton">
                    <button
                      onClick={async function () {
                        setStatusPromt(false);

                        await updateDoc(doc(db, "products", currentID), {
                          isActive: !clicked,
                        });
                        notify();
                      }}
                    >
                      Yes
                    </button>
                    <button onClick={() => setStatusPromt(false)}>No</button>
                  </div>
                </div>
              )}

              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead className="TableTitles">
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell align="left">
                        <div className="TableProduct">
                          <p> Name</p>
                          <TiArrowUnsorted color="gray" />
                        </div>
                      </TableCell>
                      <TableCell align="left">
                        <div className="TableProduct">
                          <p>CATEGORY</p>
                          <TiArrowUnsorted color="gray" />
                        </div>
                      </TableCell>

                      <TableCell align="left">
                        <div className="TableProduct">
                          <p> PRICE</p>
                          <TiArrowUnsorted color="gray" />
                        </div>
                      </TableCell>
                      {/* <TableCell align="left">
                        <div className="TableProduct">
                          {" "}
                          <p>QUANTITY</p>
                          <TiArrowUnsorted color="gray" />
                        </div>
                      </TableCell> */}
                      <TableCell align="left">
                        <div className="TableProduct">
                          <p>STATUS</p>
                          <TiArrowUnsorted color="gray" />
                        </div>
                      </TableCell>

                      <TableCell align="left">
                        <div className="TableProduct">
                          <p>ACTION</p>
                          <TiArrowUnsorted color="gray" />
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {load ? (
                      <div class="lds-spinner">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    ) : (
                      data
                        .filter((val) => {
                          if (search === "") {
                            return val;
                          } else if (
                            val.name
                              .toLowerCase()
                              .includes(search.toLowerCase())
                          ) {
                            return val;
                          }
                        })
                        .slice(firstIndex, lastIndex)
                        .map((row) => (
                          <TableRow
                            key={row.name}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {row.num}
                            </TableCell>
                            <TableCell align="left">
                              <div
                                style={{
                                  display: "flex",
                                  gap: "10px",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  className="BannertableImage"
                                  src={row.cover_pic}
                                  alt=""
                                />
                                <p>{row.name}</p>
                              </div>
                            </TableCell>
                            <TableCell align="left">{row.category}</TableCell>
                            <TableCell align="left">{row.price}</TableCell>
                            {/* <TableCell align="left">
                              {row.available_quantity}
                            </TableCell> */}

                            <TableCell align="left">
                              <Switch
                                {...label}
                                checked={row.isActive}
                                onClick={async function () {
                                  setStatusPromt(true);
                                  setCurrentID(row.id);
                                  setclicked(row.isActive);
                                }}
                              />
                            </TableCell>

                            <TableCell align="left">
                              <Link to={`/productedit/${row.id}`}>
                                <RiPencilFill
                                  className="iconAction"
                                  color="gray"
                                  size={20}
                                />
                              </Link>

                              <MdDelete
                                onClick={() => {
                                  setCurrentID(row.id);
                                  setPromt(true);
                                }}
                                className="iconAction"
                                color="gray"
                                size={20}
                              />
                            </TableCell>
                          </TableRow>
                        ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <Numbers
              rowLength={data.length}
              postPerPage={postPerPage}
              handle={handle}
              currentPage={currentPage}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductList;
