// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth"
import {getFirestore} from "firebase/firestore"
import {getStorage} from "firebase/storage";
const firebaseConfig = {
  apiKey: "AIzaSyAPzvUIpy3Rq3KuBQirUacPZfTuxE2A2e4",
  authDomain: "ethio-35d6a.firebaseapp.com",
  projectId: "ethio-35d6a",
  storageBucket: "ethio-35d6a.appspot.com",
  messagingSenderId: "516625893876",
  appId: "1:516625893876:web:3766ee55b4ac626a7f11a1",
  measurementId: "G-3X6PC7MCRM"
};
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)
export const db = getFirestore(app)
export const storage = getStorage(app);
