import React, { PureComponent, useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import "../home.css";
import { BiTrendingUp } from "react-icons/bi";
import {
  BsArrowDown,
  BsArrowUp,
  BsDot,
  BsThreeDotsVertical,
} from "react-icons/bs";
import { AiFillStar } from "react-icons/ai";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip as tool,
  Legend as legend,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Pie,
  Legend,
} from "recharts";
import { collection, doc, onSnapshot, query, setDoc } from "firebase/firestore";
import { db } from "../firebase";
import { UserAuth } from "../context/AuthContext";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  colors,
} from "@mui/material";
ChartJS.register(ArcElement, tool, legend);
const Home = () => {
  const [data, setData] = useState([]);
  const getdataChart = () => {
    const q = query(collection(db, "Customers"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const list = [];
      querySnapshot.forEach((doc) => {
        list.push(doc.data());
      });
      setData(list);
    });
  };
  useEffect(() => {
    getdataChart();
  }, []);

  const dataPie = {
    datasets: [
      {
        borderRadius: [20],
        borderWidth: [30],
        label: "poll",
        data: [6, 3],
        backgroundColor: ["#67C4A7", "#D1D5DB"],
        hoverBorderColor: ["white"],
      },
    ],
    hoverOffset: 1,
  };
  const options = {};
  const [status, setStatus] = useState([{ status: "", items: [] }]);
  const getdata = () => {
    const q = query(collection(db, "orders"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const list = [];
      querySnapshot.forEach((doc) => {
        list.push(doc.data());
      });
      setStatus(list);
    });
  };
  useEffect(() => {
    getdata();
  }, []);

  const c = status.filter((i) => i.status === "Confirmed");
  const pe = status.filter((i) => i.status === "Pending");
  const pr = status.filter((i) => i.status === "Proccessing");
  const ca = status.filter((i) => i.status === "Cancelled");
  const de = status.filter((i) => i.status === "Delivered");
  const { navBool } = UserAuth();

  const [product, setProduct] = useState([{ items: [] }]);
  const getdataa = () => {
    const q = query(collection(db, "orders"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const list = [];
      querySnapshot.forEach((doc) => {
        list.push(doc.data().items);
      });
      setProduct(list);
    });
  };
  useEffect(() => {
    getdataa();
  }, []);
  console.log(product);
  const [load, setLoad] = useState(true);

  const [dataPopular, setDataPopular] = useState([]);
  const [dataCustomers, setDataCustomers] = useState([]);

  const getdataPopular = () => {
    const q = query(collection(db, "TopProducts"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const list = [];
      querySnapshot.forEach((doc) => {
        list.push(doc.data());
      });
      setDataPopular(list);
      setLoad(false);
    });
  };
  const getdataCustomers = () => {
    const q = query(collection(db, "TopCustomers"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const list = [];
      querySnapshot.forEach((doc) => {
        list.push(doc.data());
      });
      setDataCustomers(list);
    });
  };
  useEffect(() => {
    getdataPopular();
    getdataCustomers();
  }, []);
  const PopularProduct = dataPopular.sort((a, b) => b.counter - a.counter);
  const TopCustomers = dataCustomers.sort((a, b) => b.counter - a.counter);

  return (
    <>
      <Navbar title="Home" />

      <Sidebar />
      <div className={navBool ? "HomeMove" : "HomeContain"}>
        <div className="Home">
          <div className="orders">
            <div className="order">
              <p>Pending</p>
              <div className="trend">
                <BiTrendingUp color="#65A30D" />
                <p>{(pe.length * 1) / 100}%</p>
              </div>
              <h2>{pe.length}</h2>
            </div>

            <div className="order">
              <p>Confirmed</p>
              <div className="trend">
                <BiTrendingUp color="#65A30D" />
                <p>{(c.length * 1) / 100}%</p>
              </div>
              <h2>{c.length}</h2>
            </div>

            <div className="order">
              <p>Processing</p>

              <div className="trend">
                <BiTrendingUp color="#65A30D" />
                <p>{(pr.length * 1) / 100}%</p>
              </div>

              <h2>{pr.length}</h2>
            </div>
          </div>

          <div className="statuses">
            <div className="status">
              <p>Cancelled</p>
              <h2>{ca.length}</h2>
            </div>

            <div className="status">
              <p>Delivered</p>
              <h2>{de.length}</h2>
            </div>
          </div>

          <div className="peopleContain">
            <div className="peopleBox">
              <div className="peopleBoxheader">
                <p>Popular Products</p>
                <BsThreeDotsVertical />
              </div>
              <TableContainer className="hj" component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Category</TableCell>
                      <TableCell align="left">Name</TableCell>
                      <TableCell align="left">Price</TableCell>

                      <TableCell align="left">Order(Confirmed)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {load ? (
                      <div class="lds-spinner homeloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    ) : (
                      PopularProduct.slice(0, 5).map((i) => (
                        <TableRow
                          key={i.name}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell align="left">
                            <div className="peopleBoxRowItems">
                              <img
                                src="https://th.bing.com/th/id/OIP.rGePdLTZ8-92G5lNAgzwrwHaHA?pid=ImgDet&rs=1"
                                alt=""
                              />
                              <div>
                                <p
                                  style={{
                                    fontSize: "14px",
                                    marginBottom: "4px",
                                  }}
                                >
                                  Clothes
                                </p>
                                <p style={{ color: "gray", fontSize: "12px" }}>
                                  12-10-2022
                                </p>
                              </div>
                            </div>
                          </TableCell>

                          <TableCell align="left">
                            <p style={{ color: "gray" }}>{i.name}</p>
                          </TableCell>
                          <TableCell align="left">
                            <p style={{ color: "gray" }}>{i.price}</p>
                          </TableCell>
                          <TableCell align="left">
                            <p>{i.counter}</p>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>

            <div className="peopleBox">
              <div className="peopleBoxheader">
                <p>Top Rated Products</p>
              </div>

              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Category</TableCell>
                      <TableCell align="left">Name</TableCell>
                      <TableCell align="left">Rating</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <div className="peopleBoxRowItems">
                          <img
                            src="https://th.bing.com/th/id/OIP.rGePdLTZ8-92G5lNAgzwrwHaHA?pid=ImgDet&rs=1"
                            alt=""
                          />
                          <div>
                            <p
                              style={{ fontSize: "14px", marginBottom: "4px" }}
                            >
                              Clothes
                            </p>
                            <p style={{ color: "gray", fontSize: "12px" }}>
                              12-10-2022
                            </p>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell align="left">
                        <p style={{ color: "gray" }}>Tshirt</p>
                      </TableCell>
                      <TableCell align="left">
                        <div className="gold">
                          <AiFillStar /> <AiFillStar /> <AiFillStar />{" "}
                          <AiFillStar />
                        </div>
                      </TableCell>
                    </TableRow>

                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <div className="peopleBoxRowItems">
                          <img
                            src="https://th.bing.com/th/id/OIP.rGePdLTZ8-92G5lNAgzwrwHaHA?pid=ImgDet&rs=1"
                            alt=""
                          />
                          <div>
                            <p
                              style={{ fontSize: "14px", marginBottom: "4px" }}
                            >
                              Clothes
                            </p>
                            <p style={{ color: "gray", fontSize: "12px" }}>
                              12-10-2022
                            </p>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell align="left">
                        <p style={{ color: "gray" }}>Tshirt</p>
                      </TableCell>
                      <TableCell align="left">
                        <div className="gold">
                          <AiFillStar /> <AiFillStar /> <AiFillStar />{" "}
                          <AiFillStar />
                        </div>
                      </TableCell>
                    </TableRow>

                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <div className="peopleBoxRowItems">
                          <img
                            src="https://th.bing.com/th/id/OIP.rGePdLTZ8-92G5lNAgzwrwHaHA?pid=ImgDet&rs=1"
                            alt=""
                          />
                          <div>
                            <p
                              style={{ fontSize: "14px", marginBottom: "4px" }}
                            >
                              Clothes
                            </p>
                            <p style={{ color: "gray", fontSize: "12px" }}>
                              12-10-2022
                            </p>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell align="left">
                        <p style={{ color: "gray" }}>Tshirt</p>
                      </TableCell>
                      <TableCell align="left">
                        <div className="gold">
                          <AiFillStar /> <AiFillStar /> <AiFillStar />{" "}
                          <AiFillStar />
                        </div>
                      </TableCell>
                    </TableRow>

                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <div className="peopleBoxRowItems">
                          <img
                            src="https://th.bing.com/th/id/OIP.rGePdLTZ8-92G5lNAgzwrwHaHA?pid=ImgDet&rs=1"
                            alt=""
                          />
                          <div>
                            <p
                              style={{ fontSize: "14px", marginBottom: "4px" }}
                            >
                              Clothes
                            </p>
                            <p style={{ color: "gray", fontSize: "12px" }}>
                              12-10-2022
                            </p>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell align="left">
                        <p style={{ color: "gray" }}>Tshirt</p>
                      </TableCell>
                      <TableCell align="left">
                        <div className="gold">
                          <AiFillStar /> <AiFillStar /> <AiFillStar />{" "}
                          <AiFillStar />
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <div className="peopleBoxRowItems">
                          <img
                            src="https://th.bing.com/th/id/OIP.rGePdLTZ8-92G5lNAgzwrwHaHA?pid=ImgDet&rs=1"
                            alt=""
                          />
                          <div>
                            <p
                              style={{ fontSize: "14px", marginBottom: "4px" }}
                            >
                              Clothes
                            </p>
                            <p style={{ color: "gray", fontSize: "12px" }}>
                              12-10-2022
                            </p>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell align="left">
                        <p style={{ color: "gray" }}>Tshirt</p>
                      </TableCell>
                      <TableCell align="left">
                        <div className="gold">
                          <AiFillStar /> <AiFillStar /> <AiFillStar />{" "}
                          <AiFillStar />
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>

          <div className="HomeData">
            <div className="HomeDataBox1">
              <div className="peopleBoxheader">
                <div>
                  <p style={{ marginBottom: "10px" }}>Top Customers</p>
                  <p style={{ color: "#4B5563" }}>
                    Listed by top delivered orders{" "}
                  </p>
                </div>
                <BsThreeDotsVertical size={20} color="#4B5563" />
              </div>
              {TopCustomers.slice(0, 5).map((i) => {
                return (
                  <div className="CustomerRow">
                    <div className="peopleBoxRowItems">
                      <img
                        style={{ marginLeft: "10px" }}
                        src="https://th.bing.com/th/id/OIP.rGePdLTZ8-92G5lNAgzwrwHaHA?pid=ImgDet&rs=1"
                        alt=""
                      />
                      <div>
                        <p style={{ fontSize: "14px", marginBottom: "4px" }}>
                          {i.name}
                        </p>
                        <p style={{ color: "gray", fontSize: "12px" }}>
                          {i.spent}
                        </p>
                      </div>
                    </div>

                    <div style={{ display: "flex", alignItems: "center" }}>
                      <BsArrowDown
                        color="red"
                        size={20}
                        style={{ fontWeight: "bolder" }}
                      />
                      <p
                        style={{
                          color: "gray",
                          fontSize: "15px",
                          marginLeft: "10px",
                          marginRight: "10px",
                        }}
                      >
                        {i.counter}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="HomeDataBox1 ">
              <div>
                <p style={{ color: "#4B5563", marginBottom: "10px" }}>
                  Current customers
                </p>
                <h2>{data.length}</h2>
              </div>
              <div>
                <ResponsiveContainer height={230}>
                  <AreaChart
                    data={data}
                    margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
                  >
                    <defs>
                      <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop offsa="5%" stopColor="#00FF39" stopOpacity={0} />
                        <stop
                          offset="95%"
                          stopColor="#03FE61"
                          stopOpacity={0.5}
                        />
                      </linearGradient>
                    </defs>

                    <Tooltip />
                    <Area
                      type="monotone"
                      dataKey="value"
                      stroke="green"
                      fillOpacity={1}
                      fill="url(#colorUv)"
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </div>
            <div className="HomeDataBox1">
              <div className="peopleBoxheader">
                <div>
                  <p>Total Sell</p>
                </div>
                <BsThreeDotsVertical size={20} color="#4B5563" />
              </div>
              <div className="pie">
                <Doughnut data={dataPie} options={options}></Doughnut>
                <p>80%</p>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <BsDot size={45} color="#67C4A7" />
                    <p>Earnings</p>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <BsDot size={45} color=" #D1D5DB" />
                    <p>Expenses</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
