import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AiFillHome, AiOutlineDown, AiOutlineRight } from "react-icons/ai";
import { BsChatTextFill, BsFillBasketFill, BsPeopleFill } from "react-icons/bs";
import { HiSquares2X2 } from "react-icons/hi2";
import { MdOutlineArticle } from "react-icons/md";
import { BiMessageDetail } from "react-icons/bi";
import { IoIosPeople } from "react-icons/io";
import { FaUserAlt } from "react-icons/fa";
import { GiCash } from "react-icons/gi";
import "../sidebar.css";
const Sidebar = () => {
  

  return (
   <>
   </>
  );
};

export default Sidebar;
