import React, { useState } from "react";
import logo from "../assets/kiosk.svg";
import "../navbar.css";
import { HiChevronDoubleLeft } from "react-icons/hi";
import { BsChevronDown } from "react-icons/bs";
import { UserAuth } from "../context/AuthContext";
import { MdLogout } from "react-icons/md";
import { useNavigate } from "react-router-dom";

import { Link } from "react-router-dom";
import { AiFillHome, AiOutlineDown, AiOutlineRight } from "react-icons/ai";
import { BsChatTextFill, BsFillBasketFill, BsPeopleFill } from "react-icons/bs";
import { HiSquares2X2 } from "react-icons/hi2";
import { MdOutlineArticle } from "react-icons/md";
import { BiMessageDetail } from "react-icons/bi";
import { IoIosPeople } from "react-icons/io";
import { FaUserAlt } from "react-icons/fa";
import { GiCash } from "react-icons/gi";
import "../sidebar.css";
import "../navbar.css";
import { useContext } from "react";
import { NavContext } from "../context/NavContext";

const Navbar = (props) => {
  const { navBool, setNavBool, activeItem, setActiveItem } = UserAuth();

  const { user, logOut } = UserAuth();
  const [show, setShow] = useState(false);
  const [hide, SetHide] = useState(true);

  const handleHide = () => {
    SetHide(!hide);
    setNavBool(!navBool);
  };
  const navigate = useNavigate();
  const handle = async () => {
    try {
      await logOut();
    } catch (error) {
      console.log(error);
    }
  };
  const [bool, setBool] = useState(false);
  const Dropdown = () => {
    setBool(!bool);
  };

  const handleItemClick = (item) => {
    setActiveItem(item);
  };

  const imageStyle = {
    width: "100px",
    height: "100px",
  };

  return (
    <div>
      <div className="navbar">
        <div className="navbarContain">
          <img src={logo} alt="" style={imageStyle} />
          <HiChevronDoubleLeft
            style={{ cursor: "pointer" }}
            onClick={handleHide}
            size={35}
          ></HiChevronDoubleLeft>

          <p>{props.title}</p>
        </div>
        <div className="account" onClick={() => setShow(!show)}>
          <img
            src="https://th.bing.com/th/id/OIP.cbVEYW64oNlc7QA8PK6ETAAAAA?pid=ImgDet&rs=1"
            alt=""
          />
          <div className="details">
            <p>{user.email}</p>
            {/* <p>{user.displayName}</p> */}
          </div>
          <BsChevronDown />
          {show && (
            <div className="AccountLogout" onClick={handle}>
              <p>Log Out</p>
              <MdLogout />
            </div>
          )}
        </div>
      </div>

      <div className={hide ? "sidebar" : "sidebarr"}>
        <Link className="Link " to="/home">
          <div
            id="home"
            className={`PageItems ${activeItem === "item1" ? "active" : ""}`}
            onClick={() => handleItemClick("item1")}
          >
            <div className="PageItem">
              <AiFillHome color="#22C55E" size={25} />
              <p className={hide ? "" : "Hide"}>Home</p>
            </div>
          </div>
        </Link>

        <p className={hide ? "" : "Hide"}>Pages</p>

        <Link className="Link" to="/orders">
          <div
            className={`PageItems ${activeItem === "item2" ? "active" : ""}`}
            onClick={() => handleItemClick("item2")}
          >
            <div className={"PageItem"}>
              <BsFillBasketFill size={25} color="gray" />
              <p className={hide ? "" : "Hide"}>Orders</p>
            </div>
          </div>
        </Link>
        {/*  */}

        {/*  */}

        <div
          className={`PageItems ${activeItem === "item3" ? "active" : ""}`}
          onClick={() => {
            handleItemClick("item3");

            Dropdown();
          }}
        >
          <div className="PageItem">
            <HiSquares2X2 size={25} color="gray" />
            <p className={hide ? "" : "Hide"}>Products</p>
          </div>
          <div className={hide ? "PageItem" : "Hide"}>
            {bool ? (
              <AiOutlineDown size={15} color="gray" />
            ) : (
              <AiOutlineRight size={15} color="gray" />
            )}
          </div>
        </div>
        {bool && (
          <div className="DropdownContain">
            <Link className="Link" to="/products">
              <p>
                <span className="green">•</span> Add new
              </p>
            </Link>
            <Link className="Link" to="/productlist">
              <p>
                <span className="green">•</span> List
              </p>
            </Link>
          </div>
        )}

        {/*  */}
        <Link className="Link" to="/categories">
          <div
            className={`PageItems ${activeItem === "item4" ? "active" : ""}`}
            onClick={() => handleItemClick("item4")}
          >
            <div className="PageItem">
              <MdOutlineArticle size={25} color="gray" />
              <p className={hide ? "" : "Hide"}>Categories</p>
            </div>
          </div>
        </Link>
        {/*  */}
        <Link className="Link" to="/banners">
          <div
            className={`PageItems ${activeItem === "item5" ? "active" : ""}`}
            onClick={() => handleItemClick("item5")}
          >
            <div className="PageItem">
              <BiMessageDetail size={25} color="gray" />
              <p className={hide ? "" : "Hide"}>Banners</p>
            </div>
          </div>
        </Link>

        {/*  */}
        <Link className="Link" to="/customers">
          <div
            className={`PageItems ${activeItem === "item6" ? "active" : ""}`}
            onClick={() => handleItemClick("item6")}
          >
            <div className="PageItem">
              <FaUserAlt size={25} color="gray" />
              <p className={hide ? "" : "Hide"}>Customers</p>
            </div>
          </div>
        </Link>
        {/*  */}
        <Link className="Link" to="/coupons">
          <div
            className={`PageItems ${activeItem === "item7" ? "active" : ""}`}
            onClick={() => handleItemClick("item7")}
          >
            <div className="PageItem">
              <GiCash size={25} color="gray" />
              <p className={hide ? "" : "Hide"}>Coupons</p>
            </div>
          </div>
        </Link>
        {/* <Link className="Link" to="/reviews">
        <div
           className={`PageItems ${activeItem === 'item8' ? 'active' : ''}`}
           onClick={() => handleItemClick('item8')}
        >
          <div className="PageItem">
            <BsChatTextFill size={25} color="gray" />
            <p className={hide ? "" : "Hide"}>Item Reviews</p>
          </div>
         
        </div>
      </Link> */}
        {/*  */}
      </div>
    </div>
  );
};

export default Navbar;
