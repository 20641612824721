import React, { useEffect, useState } from "react";
import { BiPlusCircle, BiSearch } from "react-icons/bi";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import "../Coupons.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Switch } from "@mui/material";
import Numbers from "../components/Numbers";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import { v4 } from "uuid";
import { AiOutlineDown } from "react-icons/ai";
import { BsChevronDown } from "react-icons/bs";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { RiPencilFill } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
const Coupons = () => {
  const handle = (i) => {
    setCurrentPage(i);
  };
  const notify = (e) =>
    toast.info(e, {
      position: "bottom-left",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setPostPerPage] = useState(15);
  const lastIndex = currentPage * postPerPage;
  const firstIndex = lastIndex - postPerPage;
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [load, setLoad] = useState(true);

  const [data, setData] = useState({
    coupon_id: "",
    code: "",
    coupon_type: "",
    discount_type: "",
    discount_amount: "",
    expiration_date: "",
    max_user: "",
    min_order_amount: "",
    min_cart_list: "",
  });

  const AddData = (e) => {
    const id = v4();
    e.preventDefault();
    const refs = doc(db, "coupons", id);
    if (data.code == "") {
      notify("You forgot to add a code");
      return;
    }
    if (data.expiration_date == "") {
      notify("You forgot to add expiration date");
      return;
    }
    if (data.discount_type == "") {
      notify("You forgot to add a discount amount");
      return;
    }
    if (data.discount_amount == 0) {
      notify("discount amount can not be 0");
      return;
    }
    if (data.max_user == "") {
      notify("You forgot to add max user");
      return;
    }
    if (data.min_order_amount == "") {
      notify("You forgot to add minimum order");
      return;
    }
    if (data.min_cart_list == "") {
      notify("You forgot to add minimum cart ");
      return;
    } else {
      setDoc(refs, {
        coupon_id: id,
        code: data.code,

        discount_type: data.discount_type,
        discount_amount: parseInt(data.discount_amount),
        expiration_date: data.expiration_date,
        max_user: parseInt(data.max_user),
        min_order_amount: parseInt(data.min_order_amount),
        min_cart_list: parseInt(data.min_cart_list),
        usage: 0,
      });
      notify("succesfully added");
    }
    setData({
      coupon_id: "",
      code: "",
      coupon_type: "",
      discount_type: "",
      discount_amount: "",
      expiration_date: "",
      max_user: "",
      min_order_amount: "",
      min_cart_list: "",
    });
  };
  const [couponList, setCouponList] = useState([]);
  const [currentID, setCurrentID] = useState("");
  const [Statuspromt, setStatusPromt] = useState(false);
  const [promt, setPromt] = useState(false);

  const [clicked, setclicked] = useState(false);
  const getdata = () => {
    const q = query(collection(db, "coupons"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const list = [];
      querySnapshot.forEach((doc) => {
        list.push(doc.data());
      });
      setCouponList(list);
      setLoad(false);
    });
  };
  useEffect(() => {
    getdata();
  }, []);
  const [search, setSearch] = useState("");

  return (
    <>
      <Navbar title="Coupons" />
      <Sidebar />
      <div className="Coupons">
        <div className="contain">
          <ToastContainer
            position="bottom-left"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
          <div className="CouponsContain">
            <div className="CouponsBox">
              <div className="ProductsTitle">
                <BiPlusCircle size={25} />
                <h3>Add New Coupon</h3>
              </div>
              <div className="CouponInputs">
                <div className="CouponsInputContain">
                  <div className="CouponsInput">
                    <label htmlFor="">Code</label>
                    <input
                      value={data.code}
                      type="text"
                      placeholder="New coupon code"
                      onChange={(e) =>
                        setData({ ...data, code: e.target.value })
                      }
                    />
                  </div>
                  <div className="CouponsInput">
                    <label htmlFor="">Expiration Date</label>

                    <input
                      value={data.expiration_date}
                      type="date"
                      name=""
                      id=""
                      onChange={(e) =>
                        setData({ ...data, expiration_date: e.target.value })
                      }
                    />
                  </div>
                </div>

                <div className="CouponsInputContain">
                  <div className="CouponsInput">
                    <label htmlFor="">Discount Type</label>
                    <select
                      value={data.discount_type}
                      name=""
                      id=""
                      onChange={(e) =>
                        setData({ ...data, discount_type: e.target.value })
                      }
                    >
                      <option value="amount">Amount</option>
                      <option value="percent">Percent</option>
                    </select>
                    <BsChevronDown className="CouponIconDown" />
                  </div>
                  <div className="CouponsInput">
                    <label htmlFor="">Discount</label>
                    <input
                      value={data.discount_amount}
                      type="text"
                      placeholder="0"
                      onChange={(e) =>
                        setData({ ...data, discount_amount: e.target.value })
                      }
                    />
                  </div>
                  <div className="CouponsInput">
                    <label htmlFor="">Max User</label>
                    <input
                      value={data.max_user}
                      type="text"
                      placeholder="0"
                      onChange={(e) =>
                        setData({ ...data, max_user: e.target.value })
                      }
                    />
                  </div>
                  <div className="CouponsInput">
                    <label htmlFor="">Min Order</label>
                    <input
                      value={data.min_order_amount}
                      type="text"
                      placeholder="0"
                      onChange={(e) =>
                        setData({ ...data, min_order_amount: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="CouponsInputContain">
                  <div className="CouponsInput">
                    <label htmlFor="">Min cart</label>
                    <input
                      value={data.min_cart_list}
                      type="text"
                      placeholder="0"
                      onChange={(e) =>
                        setData({ ...data, min_cart_list: e.target.value })
                      }
                    />
                  </div>
                </div>

                <div className="CouponsInputContain">
                  <button className="couponButton" onClick={AddData}>
                    Submit
                  </button>
                </div>
              </div>
            </div>

            <div className="CouponsPageTable">
              <div className="CouponsPageTableHeader">
                <div className="CouponNum">
                  <h3>Coupon List</h3>
                  <p>{couponList.length}</p>
                </div>

                <div className="Bannertitle2">
                  <BiSearch size={18} className="bannerIcon" />
                  <input
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    type="text"
                    placeholder="Search here"
                  />
                  <button>Search</button>
                </div>
              </div>

              <div className="CoupontableContain">
                {Statuspromt && (
                  <div className="CouponPromt">
                    <p>Are you sure you want to change status?</p>
                    <div className="ProductPromtButton">
                      <button
                        onClick={async function () {
                          setStatusPromt(false);

                          await updateDoc(doc(db, "coupons", currentID), {
                            isActive: !clicked,
                          });
                          notify();
                        }}
                      >
                        Yes
                      </button>
                      <button onClick={() => setStatusPromt(false)}>No</button>
                    </div>
                  </div>
                )}

                {promt && (
                  <div className="CouponPromt">
                    <p>Are you sure you want to delete?</p>
                    <div className="ProductPromtButton">
                      <button
                        onClick={async function () {
                          setPromt(false);

                          await deleteDoc(doc(db, "coupons", currentID));
                        }}
                      >
                        Yes
                      </button>
                      <button onClick={() => setPromt(false)}>No</button>
                    </div>
                  </div>
                )}
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead className="TableTitles">
                      <TableRow>
                        <TableCell align="left">CODE</TableCell>

                        <TableCell align="left">MIN ORDER</TableCell>
                        <TableCell align="left">MAX USER</TableCell>
                        <TableCell align="left">DISCOUNT</TableCell>
                        <TableCell align="left">DISCOUNT TYPE</TableCell>

                        <TableCell align="left">EXPIRE DATE</TableCell>
                        <TableCell align="left">MIN CART</TableCell>
                        <TableCell align="left">STATUS</TableCell>
                        <TableCell align="left">Usage</TableCell>
                        <TableCell align="left">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {load ? (
                        <div class="lds-spinner">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      ) : (
                        couponList
                          .filter((val) => {
                            if (search === "") {
                              return val;
                            } else if (
                              val.title
                                .toLowerCase()
                                .includes(search.toLowerCase())
                            ) {
                              return val;
                            }
                          })
                          .slice(firstIndex, lastIndex)
                          .map((row) => (
                            <TableRow
                              key={row.name}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell align="left">{row.code}</TableCell>
                              <TableCell align="left">
                                {row.min_order_amount}
                              </TableCell>
                              <TableCell align="left">{row.max_user}</TableCell>

                              <TableCell align="left">
                                {row.discount_amount}
                              </TableCell>

                              <TableCell align="left">
                                {row.discount_type}
                              </TableCell>

                              <TableCell align="left">
                                {row.expiration_date}
                              </TableCell>
                              <TableCell align="left">
                                {row.min_cart_list}
                              </TableCell>

                              <TableCell align="left">
                                <Switch
                                  {...label}
                                  checked={row.isActive}
                                  onClick={async function () {
                                    setStatusPromt(true);
                                    setCurrentID(row.coupon_id);
                                    setclicked(row.isActive);
                                  }}
                                />
                              </TableCell>
                              <TableCell align="left">{row.usage}</TableCell>

                              <TableCell align="left">
                                <Link to={`/couponedit/${row.coupon_id}`}>
                                  <RiPencilFill
                                    className="iconAction"
                                    color="gray"
                                    size={20}
                                  />
                                </Link>

                                <MdDelete
                                  onClick={() => {
                                    setCurrentID(row.coupon_id);
                                    setPromt(true);
                                  }}
                                  className="iconAction"
                                  color="gray"
                                  size={20}
                                />
                              </TableCell>
                            </TableRow>
                          ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <Numbers
                rowLength={couponList.length}
                postPerPage={postPerPage}
                handle={handle}
                currentPage={currentPage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Coupons;
