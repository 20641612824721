import React, { useEffect, useState } from "react";
import { BiPlusCircle, BiSearch } from "react-icons/bi";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import "../Coupons.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Switch } from "@mui/material";
import Numbers from "../components/Numbers";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import { v4 } from "uuid";
import { AiOutlineDown } from "react-icons/ai";
import { BsChevronDown } from "react-icons/bs";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate, useParams } from "react-router-dom";
import { RiPencilFill } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
const CouponEdit = () => {
  const handle = (i) => {
    setCurrentPage(i);
  };
  const notify = (e) => toast.info(e, {
    position: "bottom-left",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
    });
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setPostPerPage] = useState(15);
  const lastIndex = currentPage * postPerPage;
  const firstIndex = lastIndex - postPerPage;
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [load, setLoad] = useState(true);
  const navigate = useNavigate();
  const [data, setData] = useState({
    coupon_id: "",
    code: "",
    coupon_type: "",
    discount_type: "",
    discount_amount: "",
    expiration_date: "",
    max_user: "",
    min_order_amount: "",
    min_cart_list: "",
  });

  const AddData = (e) => {
  
    e.preventDefault();
    const refs = doc(db, "coupons", item_id.id);
   if(data.code == ""){
notify("You forgot to add a code")
   }
   if(data.expiration_date == ""){
    notify("You forgot to add expiration date")
       }
       if(data.max_user == ""){
        notify("You forgot to add max user")
           }
           if(data.min_order_amount == ""){
            notify("You forgot to add minimum order")
               }
               if(data.min_cart_list == ""){
                notify("You forgot to add minimum cart ")
                   }
           
   else{
    setDoc(refs, {
      coupon_id: item_id.id,
      code: data.code,

      discount_type: data.discount_type,
      discount_amount: parseInt(data.discount_amount),
      expiration_date: data.expiration_date,
      max_user: parseInt(data.max_user),
      min_order_amount: parseInt(data.min_order_amount),
      min_cart_list: parseInt(data.min_cart_list),
     usage: parseInt(data.usage),
    });
    notify("succesfully updated")
   }
    setData({
      coupon_id: "",
      code: "",
      coupon_type: "",
      discount_type: "",
      discount_amount: "",
      expiration_date: "",
      max_user: "",
      min_order_amount: "",
      min_cart_list: "",
    }); 
   navigate("/coupons")
  }
  const [couponList, setCouponList] = useState([]);
  const [currentID, setCurrentID] = useState("");
  const [Statuspromt,setStatusPromt] = useState(false)
  const [promt,setPromt] = useState(false)

  const [clicked,setclicked] = useState(false)
  const getdata = () => {
    const q = query(collection(db, "coupons"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const list = [];
      querySnapshot.forEach((doc) => {
        list.push(doc.data());
      });
      setCouponList(list);
      setLoad(false)
    });
  };
  useEffect(() => {
    getdata();
  }, []);
  const item_id = useParams()
  const [search, setSearch] = useState("");
  const GetData = async () => {
    const docRef = doc(db, "coupons", item_id.id);
    const docSnap = await getDoc(docRef);
    setData(docSnap.data());
  };
  useEffect(() => {
    GetData();
  }, []);

  return (
    <>
      <Navbar title="Coupons" />
      <Sidebar />
      <div className="Coupons">
        <div className="contain">
        <ToastContainer
position="bottom-left"
autoClose={3000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="dark"
/>
          <div className="CouponsContain">
            <div className="CouponsBox">
              <div className="ProductsTitle">
                <RiPencilFill size={25} />
                <h3>Edit Coupon</h3>
              </div>
              <div className="CouponInputs">
                <div className="CouponsInputContain">
                  <div className="CouponsInput">
                    <label htmlFor="">Code</label>
                    <input
                  
                      type="text"
                      value={data.code}
                      placeholder="New coupon code"
                      onChange={(e) =>
                        setData({ ...data, code: e.target.value })
                      }
                    />
                  </div>
                  <div className="CouponsInput">
                    <label htmlFor="">Expiration Date</label>

                    <input
                      type="date"
                      name=""
                      id=""
                      value={data.expiration_date}
                      
                      onChange={(e) =>
                        setData({ ...data, expiration_date: e.target.value })
                      }
                    />
                  </div>
                </div>

                <div className="CouponsInputContain">
                  <div className="CouponsInput">
                    <label htmlFor="">Discount Type</label>
                    <select
                      name=""
                      id=""
                      value={data.discount_type}
                      onChange={(e) =>
                        setData({ ...data, discount_type: e.target.value })
                      }
                    >
                      <option value="amount">Amount</option>
                      <option value="percent">Percent</option>
                      <option value="none">none</option>
                    </select>
                    <BsChevronDown className="CouponIconDown" />
                  </div>
                  <div className="CouponsInput">
                    <label htmlFor="">Discount</label>
                    <input
                      type="text"
                      value={data.discount_amount}
                      placeholder="0"
                      onChange={(e) =>
                        setData({ ...data, discount_amount: e.target.value })
                      }
                    />
                  </div>
                  <div className="CouponsInput">
                    <label htmlFor="">Max User</label>
                    <input
                      type="text"
                      value={data.max_user}
                      placeholder="0"
                      onChange={(e) =>
                        setData({ ...data, max_user: e.target.value })
                      }
                    />
                  </div>
                  <div className="CouponsInput">
                    <label htmlFor="">Min Order</label>
                    <input
                    value={data.min_order_amount}
                      type="text"
                      placeholder="0"
                      onChange={(e) =>
                        setData({ ...data, min_order_amount: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="CouponsInputContain">
                  <div className="CouponsInput">
                    <label htmlFor="">Min cart</label>
                    <input
                    value={data.min_cart_list}
                      type="text"
                      placeholder="0"
                      onChange={(e) =>
                        setData({ ...data, min_cart_list: e.target.value })
                      }
                    />
                  </div>
                </div>

                <div className="CouponsInputContain">
                  <button className="couponButton" onClick={AddData}>
                    Submit
                  </button>
                </div>
              </div>
            </div>

          
          </div>
        </div>
      </div>
    </>
  );
};

export default CouponEdit;
