import React, { useEffect, useState } from "react";
import "../Category.css";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import defaultimage from "../assets/d.png";

import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import {  collection, doc, getDoc, onSnapshot, query, setDoc } from "firebase/firestore";
import { db, storage } from "../firebase";
import { v4 } from "uuid";
import { BsChevronDown } from "react-icons/bs";
import { Link, useNavigate, useParams } from "react-router-dom";

const CategoryEdit = () => {
  

const item_id = useParams()

  const [filename, setFileName] = useState("Choose File");
  const [img, setImg] = useState();
  const [priority, setPriority] = useState("");
 
  const handlefile = (event) => {
    const file = event.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      localStorage.setItem("image", reader.result);
      setImg(localStorage.getItem("image"));
    };

    var x = event.target.files[0].name;
    setFileName(x);
    setImg(event.target.value);
  };

  const [category, setCategory] = useState("");
  const [status, setStatus] = useState();
  const [imgFile, setImgFile] = useState("");

const navigate = useNavigate()
  const AddData = (e) => {
    e.preventDefault();
   

    const refs = doc(db, "category", item_id.id);
    const imgRef = ref(storage, new Date().toString());
  if(imgFile){
    const uploadTask = uploadBytesResumable(imgRef, imgFile);
    uploadTask.on(
      (error) => {
        console.log(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          
            setDoc(refs, {
            category_id: item_id.id,
            name: category,
            status: status,
            img:downloadURL,
            priority : priority
          })
  
        });
      }
    );
  
  }
  
  setDoc(refs, {
    category_id: item_id.id,
    name: category,
    status: status,
    img:img,
    priority:priority
  })
    navigate("/categories")
  };

  const GetData =async () => {
    const docRef = doc(db, "category", item_id.id);
const docSnap = await getDoc(docRef);
setCategory(docSnap.data().name)
setStatus(docSnap.data().status)
setImg(docSnap.data().img)
setPriority(docSnap.data().priority)


}
useEffect(() => {
GetData()
}, []);


console.log(priority)
  return (
    <>
      <Navbar title="Category" />
      <Sidebar />
      <div className="Category">
        <div className="contain">
          <div className="CategoryContain">
            <p>Category</p>
            <div className="CategoryBox">
              <p>Add New Category</p>
              <hr />
              <div className="CategoryInputs">
                <div className="CategoryInput">
                  <label>Name</label>
                  <input
                  value={category}
                    type="text"
                    placeholder="New Category"
                    onChange={(e) => {
                      setCategory(e.target.value);
                    }}
                  />
                </div>
                <div className="CategoryInput">
                  <label>Priority</label>
                  <input
                  value={priority}
                    type="text"
                    placeholder="0"
                    onChange={(e) => {
                      setPriority(e.target.value);
                    }}
                  />
                </div>
                <div className="CategoryInput">
                  <label>Status</label>
                  <select
                  value={status}
                  onChange={(e) => {
                    if (e.target.value === "true") {
                      setStatus(true)
                    } else {
                      setStatus(false)

                    }
                  }}
                  >
                    <option value="" disabled>--Status--</option>
                    <option value={"true"}>Available</option>
                    <option value={"false"}>Not Available</option>
                  </select>
                  <BsChevronDown className="iconDownCategory" />
                </div>

                <div className="CategoryInput">
                  <div className="ProductsInput6">
                    <p style={{ color: "gray" }}>
                      Image <span style={{ color: "red" }}> * (Ratio 1:1)</span>
                    </p>
                    <div class="custom-file-wrp2">
                      <div class="filename">{filename}</div>
                      <div className="custom-file">
                        <label htmlFor="fileupload">Browse</label>
                        <input
                          type="file"
                          id="fileupload"
                          onChange={(e) => {
                            handlefile(e);
                            setImgFile(e.target.files[0]);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <img className="CategoryImage" src={img} alt="" />

                <div>
                  <button className="CategoryButton" onClick={AddData}>
                   Save
                  </button>
                </div>
              </div>
            </div>
         
     </div>

        
        </div>
      </div>
    </>
  );
};

export default CategoryEdit;
