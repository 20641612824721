import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { AiFillStar } from "react-icons/ai";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import Rating from "@prontopro/react-rating";
import "../Review.css";
import Numbers from "../components/Numbers"
import { collection, doc, getDoc, onSnapshot, query, setDoc } from "firebase/firestore";
import { db } from "../firebase";
const Reviews = () => {
  

  const [load, setLoad] = useState(true);

  const [data, setData] = useState([]);

  
 

  const [value, setValue] = useState();
  const handle = (i) => {
    setCurrentPage(i);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setPostPerPage] = useState(15);
  const lastIndex = currentPage * postPerPage;
  const firstIndex = lastIndex - postPerPage;
  const [userData, setUserData] = useState({is_active:false});
  const [userDataProduct, setUserDataProduct] = useState({is_active:false});


  useEffect(() => {
    const getdata = () => {
      const q = query(collection(db, "orders"));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const list = [];
        querySnapshot.forEach((doc) => {
          list.push(doc.data());
        });
        setData(list);
        setLoad(false);
  
        
      });
    };
    const fetchData = async () => {
      const newData = {};
      const promises = data.map((user) => {
        const docRef = doc(db, "users", user.reviews?.user_id);
        return getDoc(docRef).then((docSnap) => {
          newData[user.reviews?.user_id] = docSnap.data().name;
        });
      });
      await Promise.all(promises);
      setUserData(newData);
      
    };
    const fetchDataProduct = async () => {
      const newData = {};
      const promises = data.map((user) => {
        const docRef = doc(db, "products", user.items[0]?.product_id);
        return getDoc(docRef).then((docSnap) => {
          newData[user.items[0]?.product_id] = docSnap.data()?.cover_pic;
          
        });
      });
      await Promise.all(promises);
      setUserDataProduct(newData);
      
      
    };
    getdata()
    fetchData();
    fetchDataProduct();
    
  }, [data]);


  return (
    <>
      <Navbar title="Item Reviews" />
      <Sidebar />
      <div className="Review">
        <div className="contain">
          <div className="ReviewContain">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 1250 }} aria-label="simple table">
                <TableHead className="TableTitles">
                  <TableRow>
                   
                    <TableCell align="left">Customer</TableCell>
                    <TableCell align="left">Item</TableCell>
                    <TableCell align="left">Comment</TableCell>
                    <TableCell align="left">Rating</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {
                      
                      
                      load ? (
                        <div class="lds-spinner">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      ) : (data.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                    
                      <TableCell align="left">{userData[row.reviews?.user_id]}</TableCell>
                      <TableCell align="left">
                        <div className="ReviewImg">
                          <img src={userDataProduct[row.items[0]?.product_id]} alt="" />
                          <p>{row.items[0]?.name}</p>
                        </div>
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {row.reviews.comment}
                      </TableCell>

                      <TableCell component="th" scope="row">
                        <Rating initialRate={row.reviews?.rating} readonly={true} />
                      </TableCell>
                    </TableRow>
                  )))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <Numbers
              rowLength={data.length}
              postPerPage={postPerPage}
              handle={handle}
              currentPage={currentPage}
            />
        </div>
      </div>
    </>
  );
};

export default Reviews;
